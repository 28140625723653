<template>
  <div style="width: 80%; margin: auto;">
    <div class="agreementBody" v-loading="loading">
      <el-form :model="form" ref="form" :rules="rules" label-width="150px" class="editForm">
        <div class="agreementFormHead">项目服务委托书</div>
        <div style="text-align: end">协议编号：{{form.protocolNumber}}</div>
        <div class="agreementHead">委托方</div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="机构名称" prop="orgName">
              <el-input type="select" style="width: 40%" disabled="true" v-model="form.orgName"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="客户负责人" v-if="pageOprator=='QG-manage' || pageOprator=='QG-apply'">
              <el-input type="select" style="width: 40%" disabled="true" v-model="applicantName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="项目负责人">
              <el-input type="select" style="width: 40%" disabled="true" v-model="form.wtManager"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系电话">
              <el-input type="select" style="width: 40%" disabled="true" v-model="form.wtManagerPhone"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="电子邮箱">
              <el-input type="select" style="width: 40%" disabled="true" v-model="form.wtManagerEmail"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="agreementHead">受托方</div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="机构名称">
              <el-input type="select" style="width: 40%" disabled="true" v-model="form.stOrgName"
                        placeholder="请输入"></el-input>
              <!--<el-input v-model="form.stOrgName" style="width: 40%" placeholder="请输入"></el-input>-->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="受托方负责人">
              <el-select type="select" :value="form.stManager" :disabled="isDisadbled" style="width: 40%"
                         @change="changeStManager">
                <el-option
                  v-for="item in form.stManagerOptions"
                  :key="item.ciicPerId"
                  :label="item.ciicPerName"
                  :value="item.ciicPerId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="联系电话">
              <el-input type="select" style="width: 40%" disabled="true" v-model="form.stManagerPhone"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="电子邮箱">
              <el-input type="select" style="width: 40%" disabled="true" v-model="form.stManagerEmail"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="agreementHead">客户信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="客户名称" >
              <el-select :disabled="true" style="width: 90%" v-model="form.cusName" placeholder="请输入"></el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="" v-if="pageOprator=='QG-manage' || pageOprator=='QG-apply'">
              <el-button type="info" @click="downloadAttach()" icon="el-icon-download" round>下载附件</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="agreementHead">服务事项及价格</div>
        <el-table
          border
          size="mini"
          :data.sync="priceList"
          :span-method="spanMethod"
        >
          <el-table-column type="index" :index="indexMethod" label="序号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="region" label="地区" width="120" show-overflow-tooltip></el-table-column>
          <!--<el-table-column prop="orgName" label="供应商" width="200" show-overflow-tooltip></el-table-column>-->
          <el-table-column prop="bizTypeName" label="业务类型" width="130" show-overflow-tooltip></el-table-column>
          <el-table-column prop="serviceBusinessName" label="服务业务" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="serviceItem" label="服务项目" width="400" show-overflow-tooltip></el-table-column>
          <el-table-column prop="handlePeriod" label="办理周期" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
          <el-table-column prop="packPrice" label="参考价格" width="100"></el-table-column>
        </el-table>

        <div class="agreementHead">付款计划</div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="付款计划">
              <el-input type="select" :disabled="true" style="width: 40%" v-model="form.payPlanName"
                        placeholder="请输入"></el-input>
              <span style="color: #e75700;" v-if="form.payPlan=='33760001'">*<b>与完成委托服务所需款项同时支付</b></span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="注意事项">
              <el-input type="textarea" :disabled="true" style="width: 40%" v-model="form.mattersAttention"
                        placeholder=""></el-input>
              <span style="color: #e75700;" v-if="form.payPlan=='33760002'">*<b>付款计划为其他时，此项必填</b></span>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="agreementHead">发票寄送信息</div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="发票抬头">
              <el-input type="select" :disabled="true" style="width: 40%" v-model="form.billsTitle"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="收件人/部门">
              <el-input type="select" :disabled="true" style="width: 40%" v-model="form.billsRecipient"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="邮寄地址">
              <el-input type="select" :disabled="true" style="width: 40%" v-model="form.billsPostcode"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="agreementHead">受托方银行信息</div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="账户名称">
              <el-select type="select" :disabled="isDisadbled" style="width: 40%" v-model="form.stAccountName"
                         @change="changeStAccount">
                <el-option
                  v-for="item in form.stAccountOptions"
                  :key="item.company"
                  :label="item.company"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="开户银行">
              <el-input type="select" disabled="true" style="width: 40%" v-model="form.stAccountBank"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="银行账号" prop="stAccountNum">
              <el-input type="select" disabled="true" style="width: 40%" v-model="form.stAccountNum"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="agreementHead"  v-if="form.terminiteReason && applyType=='33730003'">终止原因:</div>
        <el-row  v-if="form.terminiteReason && applyType=='33730003' ">
          <el-col :span="12">
            <el-form-item label="终止原因:" prop="backReason">
              <span>{{this.form.terminiteReason}}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="agreementHead"  v-if="form.agreementStatus=='33740008' ">退回原因:</div>
        <el-row  v-if="form.agreementStatus=='33740008' ">
          <el-col :span="12">
            <el-form-item label="退回原因:" prop="backReason">
              <span>{{this.form.backReason}}</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="form.agreementStatus=='33740007'">
          <el-col :span="10" style="height: 100px; margin-top: 80px;text-align: center; position: relative; font-size: medium">委托方确认章:
            <img v-if="form.isTestEnv == '00901'" class="zhang" :src="img1060344test">
            <img v-else class="zhang" :src="img1060344">
          </el-col>
          <el-col :span="10" style="height: 100px; margin-top: 80px;text-align: center; position: relative; font-size: medium">受托方确认章:
            <!--<img class="zhang" :src="img1060344">-->
          </el-col>
        </el-row>
      </el-form>

      <div style="padding:20px 0px  30px  0px" v-if="applyStatus == '00900' && pageOprator=='QG-apply'">
        <div style="text-align: center">
          <el-button @click="_auditPass">通 过</el-button>
          <el-button @click="_auditNotPass">驳 回</el-button>
        </div>
      </div>

      <div style="padding:20px 0px  30px  0px" v-if="pageOprator=='QG-manage' && form.agreementStatus=='33740008'">
        <div style="text-align: center">
          <el-button @click="_auditNotPass">驳 回</el-button>
        </div>
      </div>

      <div style="padding:20px 0px  30px  0px" v-if="pageOprator=='FZ-manage' && form.agreementStatus=='33740006'">
        <div style="text-align: center">
          <el-button @click="_confirm">生效确认</el-button>
          <el-button @click="_back">退 回</el-button>
        </div>
      </div>
    </div>
    <!-- 审核退回 -->
    <bsp-dialog
      dialog-ref="auditBackPage"
      :dialog-visible.sync="backDialog"
      width="500px"
      :title="backTitle"
      @closed="backDialog = false; loading = false"
      v-loading="loading"
    >
      <div slot="body">
        <audit-back-page ref="auditBackRef" @cancelLoadBtn="cancelLoadBtn" :passFlag="passFlag" @back="goback"></audit-back-page>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="backDialog = false">取 消</el-button>
        <el-button @click="auditBack">确 定</el-button>
      </div>
    </bsp-dialog>
  </div>
</template>

<script>
  import BspTable from '@/components/bsp/bsp-table/bsp-table'
  import BspTableColumn from '@/components/bsp/bsp-table-column/bsp-table-column'
  import img1060344 from '@/assets/img/1060344.png';
  import img1060344test from '@/assets/img/1060344-test.png';
  import {
    agreementConfirm,
    agreementStBack,
    auditBackApply,
    auditPassApply,
    getFinTaxAgreementApplyList,
    getFinTaxAgreementDetailInfo,
    getFinTaxPriceList,
    downLoadFinTaxAttach
  } from '@/api/agreement/agreement';
  import {mapGetters} from 'vuex'
  import BspDialog from "@/components/bsp/bsp-dialog/bsp-dialog";
  import {downloadFile} from '@/util/util'
  import {codeList, getCodeNames, init} from '@/components/bsp/code/frontCode'
  import auditBackPage from '@/views/agreement/fintax/auditBackPage'

  export default {
  name: 'finTaxAgreementDetail',
	components: {
		BspTable,
		BspTableColumn,
    BspDialog,
    auditBackPage
	},
  props: {
    pageOprator: null,
    id: null,
    applyStatus: null,
    agreementStatus: null,
    applicantName: null,
    applyId: null,
    applyType: null,
  },
	computed: {
		...mapGetters(['dicts', 'userInfo'])
	},
	data() {
		return {
      img1060344,
      img1060344test,
		  loading: false,
      isDisadbled: true,
      backTitle: '',
      passFlag: true,
      backDialog: false,
			stOptions: [],
      priceList: [],
      QGisEdit: false,//全国是否编辑过协议申请
			form: {
        applyId: '',
			},
			rules: {
				contractName: [
					{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}
				],
			},
      spanArr: [],
      pos: 0
		}
	},
	created() {
    this.getPriceList();//获取协议价格
    if(this.pageOprator == 'QG-apply' && this.applyStatus == '00900'){
      this.isDisadbled = false;
    }
    if (this.id) {
      this.getData(this.id);
    }
	},
	watch: {
		oneceServices(val) {
			this.form.oneceService = ''
			for (let i = 0; i < val.length; i++) {
				if (i === val.length - 1) {
					this.form.oneceService = this.form.oneceService + val[i]
				} else {
					this.form.oneceService =
						this.form.oneceService + val[i] + ','
				}
			}
		}
	},
	methods: {
    getSpanArr(data) {
      // data就是我们从后台拿到的数据
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].mergeGroupId === data[i - 1].mergeGroupId && data[i].mergeGroupId != null) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    // 合并单元格
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property == 'packPrice') {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },

    downloadAttach() {
      debugger
      downLoadFinTaxAttach(this.form.agreementId).then(res => {
        debugger
        downloadFile(res);
      })
    },

    /*获取协议服务价格*/
    getPriceList(){
      getFinTaxPriceList(this.id).then(res => {
        this.priceList = res.data.data;
        this.getSpanArr(this.priceList);
      })
    },

    // 获取详情表单数据
		async getData(id) {
		  this.loading = true;
      getFinTaxAgreementDetailInfo(id).then(res => {
          this.loading = false;
          debugger
          this.form = res.data.data;
        }).catch(erro => {
          this.loading = false;
        })
		},
    /*修改受托账号*/
    changeStAccount(row){
      this.form.stAccountOptions.forEach(res=>{
        if(res.id == row){
          this.QGisEdit = true;
          this.form.stAccountId = res.id;
          this.form.stAccountBank = res.bank;
          this.form.stAccountNum = res.account;
        }
      })
    },
    /*全国修改受托操作人*/
    changeStManager(row){
      this.form.stManagerOptions.forEach(res=>{
        if(res.ciicPerId == row){
          this.QGisEdit = true;
          this.form.stManagerId = res.ciicPerId;
          this.form.stManager = res.ciicPerName;
          this.form.stManagerPhone = res.phone;
          this.form.stManagerEmail = res.ciicPerMail;
        }
      })
    },

    /*全国审核通过*/
    _auditPass() {
      if(this.QGisEdit){
        this.$confirm(
          '审核通过将默认保存您对页面的修改，请确认',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          this.form.applyId = this.applyId;
          this.form.applyType = this.applyType;
          debugger
          auditPassApply(this.form).then(res => {
            if(res.data.code == 0){
              this.$message.success('协议审核通过');
              this.applyStatus = '00901';
              this.$emit("goback");
            } else {
              this.$message.error('协议审核失败，请联系运维');
            }
          })
        }).catch(() => {
        })
      } else {
        this.$confirm(
          '审核通过将进入协议管理模块，请确认',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          this.form.applyId = this.applyId;
          this.form.applyType = this.applyType;
          auditPassApply(this.form).then(res => {
            if(res.data.code == 0){
              this.$message.success('协议审核通过');
              this.applyStatus = '00901';
              this.isDisadbled = true;
              this.$emit("goback");
            } else {
              this.$message.error('协议审核失败，请联系运维');
            }
          })
        })
      }
    },

    goback(){
      this.applyStatus = '00901';
      this.isDisadbled = true;
      this.$emit("goback");
    },

    /**
     * 全国审核退回
     * @private
     */
    _auditNotPass() {
      console.log('this.backDialog=='+this.backDialog);
      this.backTitle = '驳回原因';
      this.backDialog = true;
    },
    /*审核\受托退回*/
    async auditBack(){
      if(this.QGisEdit){
        this.$confirm(
          '驳回批次将默认撤销页面上的修改，请确认',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
            this.form.applyId = this.applyId;
            this.form.applyType = this.applyType;
            this.$refs.auditBackRef.auditBack(this.form);
          }).catch(() => {
          })
      } else {
        if(this.pageOprator == 'QG-apply' || this.pageOprator == 'QG-manage'){
          this.form.applyId = this.applyId;
          this.form.applyType = this.applyType;
          this.$refs.auditBackRef.auditBack(this.form, this.pageOprator,'协议审核驳回成功');
        } else if(this.pageOprator == 'FZ-manage'){
          this.form.applyId = this.applyId;
          this.form.applyType = this.applyType;
          await this.$refs.auditBackRef.agreementStBack(this.form);
        }
      }
    },

    /*受托确认*/
    _confirm(){
      this.$confirm(
        '协议确认生效后将不可撤回，如需终止协议请联系委托方负责人，请确认',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        this.form.applyId = this.applyId;
        debugger
        agreementConfirm(this.form).then(res => {
          if(res.data.code == 0){
            this.$message.success('协议已生效');
            this.applyStatus = '00901';
            this.$emit("goback");
          } else {
            this.$message.error('协议生效确认时发生错误，请联系运维');
          }
        })
      }).catch(() => {
      })
    },

    /*受托退回*/
    _back(){
      this.backTitle = '退回原因';
      this.backDialog = true;
    },

    cancelLoadBtn(){
      console.log('取消加载样式');
      this.loading = false;
    },
	}
}
</script>

<style scoped>
.agreementBody {
	margin-left: 50px;
}

.agreementHead {
	padding: 0px 10px;
	margin: 5px 0px;
	font-weight: 700;
	/*border-left: 2px solid red;*/
}

.agreementFormHead {
  padding: 0px 10px;
  margin: 5px 0px;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}
  .zhang {
    position: absolute;
    width: 150px;
    height: 150px;
    right: 38px;
    top: -60px;
  }
</style>
